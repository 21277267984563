import React, { useState, useMemo } from "react";
import queries from "../helpers/queries";
import { useQuery } from "@apollo/react-hooks";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { Country, State } from "country-state-city";
import Slider from "../Components/slider";

const Packages = withRouter(({ history }) => {
    let CountryList = [
        Country.getCountryByCode("JP"),
        Country.getCountryByCode("KR"),
        Country.getCountryByCode("CN"),
        Country.getCountryByCode("TW"),
        Country.getCountryByCode("IN"),
        Country.getCountryByCode("TH"),
        Country.getCountryByCode("VN"),
    ];
    const { handleSubmit, errors } = useForm({
        defaultValues: {},
    });

    const params = new URLSearchParams(window.location.search);
    const country = params.get("country");
    const area = params.get("area");
    const state = params.get("state");

    const filterSelectStyles = useMemo(
        () => ({
            control: (provided, state) => ({
                ...provided,
                borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                backgroundColor: "#fbfdfe",
                borderRadius: "0.5rem",
                padding: "0.20rem",
                "&:hover": {
                    borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                },
            }),

            placeholder: (provided, state) => ({
                ...provided,
                color: "#a0aec0",
                fontSize: "0.75rem",
                fontWeight: "600",
            }),

            container: (provided, state) => ({
                ...provided,
                color: "#64748b",
                fontSize: "0.75rem",
                fontWeight: "600",
                width: "100%",
            }),
        }),
        [errors]
    );

    const updateFilters = (values) => {
        setFilters((prev) => ({
            ...prev,
            ...values,
            area: values.area ? values.area.value : "",
            state: values.state ? values.state.value : "",
        }));
        setPaginationState((prev) => ({ ...prev, page: 1 }));
    };

    const clearFilters = () => {
        setFilters({
            country: null,
            state: null,
            package: null,
        });
    };

    const [packages, setPackages] = useState([]);

    const [paginationState, setPaginationState] = useState({
        limit: 20,
        page: 1,
    });

    const [filters, setFilters] = useState({
        area: null,
        state: null,
    });

    useQuery(queries.FETCH_TOURS, {
        variables: {
            limit: paginationState.limit,
            page: paginationState.page,
            area: filters.area ? filters.area : area ? area : "",
            state: filters.state ? filters.state.value : state ? state : "all",
            package: filters.package ? filters.package.value : "",
            country: filters.country ? filters.country.value : country ? country : "",
        },
        fetchPolicy: "network-only",
        onCompleted: ({
            fetchPackagesAgent: { pagination, ...fetchPackagesAgent },
        }) => {
            setPaginationState(pagination);
            setPackages(fetchPackagesAgent.data);
        },
        onError: ({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                for (const graphQLError of graphQLErrors) {
                    console.log(graphQLError);
                }
            }

            if (networkError) {
                console.log(networkError);
            }
        },
    });

    const getAllCountries = () => {
        return CountryList.map((country) => ({
            value: country.isoCode, // Use isoCode as the value for the option
            label: country.name, // Use country name as the label for the option
        }));
    };

    const getStatesForCountry = (countryIsoCode) => {
        const states = State.getStatesOfCountry(countryIsoCode);
        return states.map((state) => ({
            value: state.isoCode,
            label: state.name,
        }));
    };

    return (
        <div>
            <div className="flex h-full font-futura-pt-book">
                <div className="rounded-md w-full">
                <Slider />
                    <form
                        className="relative px-24 flex flex-col lg:flex-row justify-center items-center mb-6 col-gap-4 row-gap-4 p-12 bg-longrich-blue"
                        style={{
                           
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                        onSubmit={handleSubmit(updateFilters)}
                    >
                        {/* Black overlay */}
                        <div className="absolute inset-0 bg-black opacity-50"></div>
                        <div className="lg:w-128 w-64 relative">
                            <div className="text-white text capitalize text-left lg:text-left lg:block hidden">
                                Area
                            </div>
                            <Select
                                styles={filterSelectStyles}
                                placeholder="Search by Area"
                                isSearchable={true}
                                options={
                                     [{
                                        label: "Asia",
                                        value: "Asia",
                                    },
                                    {
                                        label: "China",
                                        value: "China",
                                    },
                                    {
                                        label: "Europe",
                                        value: "Europe",
                                    },
                                    {
                                        label: "Cruises",
                                        value: "Cruises",
                                    }]
                                }
                                value={filters.area}
                                onChange={(val) =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        area: val.value,
                                    }))
                                }
                            />
                        </div>
                        <div className="lg:w-128 w-64 relative">
                            <div className="text-white text capitalize text-left lg:text-left lg:block hidden">
                                Country
                            </div>
                            <Select
                                styles={filterSelectStyles}
                                isSearchable={true}
                                options={getAllCountries()}
                                value={filters.country}
                                placeholder="Search by Country"
                                onChange={(val) =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        country: val,
                                        // Reset state and package filters when country changes
                                        state: null,
                                        package: null,
                                    }))
                                }
                            />
                        </div>
                        <div className="lg:w-128 w-64 relative">
                            <div className="text-white text capitalize text-left lg:text-left lg:block hidden">
                                State
                            </div>
                            <Select
                                styles={filterSelectStyles}
                                isSearchable={true}
                                options={
                                    filters.country
                                        ? getStatesForCountry(
                                              filters.country.value
                                          )
                                        : []
                                }
                                value={filters.state}
                                placeholder="Search by State"
                                onChange={(val) =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        state: val,
                                        // Reset package filter when state changes
                                        package: null,
                                    }))
                                }
                            />
                        </div>

                        <div className="lg:w-128 w-64 relative">
                            {/* Button with Package label */}
                            <button
                                className="bg-yellow-400 capitalize font-semibold text-left mt-6 lg:text-left lg:block hidden px-6 py-2 rounded-lg border border-black hover:bg-black hover:text-yellow-400"
                                onClick={() => clearFilters()}
                            >
                                Reset Filters
                            </button>
                        </div>
                    </form>

                   
                    <div className="lg:p-6 p-2 flex justify-center">
  {/* Center the grid */}
  <div className="grid grid-cols-1 lg:grid-cols-5 gap-4 text-white w-products">
    {packages
      ? packages.map((item, i) => {
          return (
            <div
              onClick={() => history.push(`/packages/${item._id}`)}
              className="border border-black rounded-lg overflow-hidden relative group cursor-pointer"
              key={i}
            >
              {/* Title at the top */}
              <div className="bg-longrich-blue text-white text-lg text-center py-2 font-semibold h-16 hidden lg:block">
                {item.name}
              </div>

              {/* Image with zoom effect */}
              <div className="overflow-hidden relative transition-transform duration-300 ease-in-out ">
                <img
                  className="h-72 w-full object-cover lg:object-contain transition-transform duration-300 ease-in-out"
                  src={item.image}
                  alt={item.name}
                />
                {/* Overlay at the bottom */}
                <div className="absolute lg:text-2xl text-lg bottom-0 w-full bg-black bg-opacity-75 text-longrich-orange text-center py-4">
                  <span className="text-white lg:text-lg text-lg">FROM</span> RM{" "}
                  {item.tours[0] ? item.tours[0].adultPrice : "-"}
                </div>
              </div>
            </div>
          );
        })
      : ""}
  </div>
</div>





                </div>
            </div>
        </div>
    );
});

export default Packages;
