import styled from "styled-components";
import { mobile } from "../responsive";

const Container = styled.div`
    background: #f5fafd;
    display: grid;
    align-items: center;
    grid-template-columns: 50% 50%;
    flex-direction: row;
    ${mobile({ padding: "0rem 0", display: "flex", flexDirection: "column" })}
`;
const Title = styled.h1`
    padding: 0 2rem;
    font-family: FuturaPT-Bold;
    font-size: 2.5rem;
    color: #1f2f4d;
    text-align: center;
    margin: 0rem 0rem 1rem 0;
    ${mobile({
        display: "block",
        textAlign: "center",
        fontSize: "1.5rem",
        padding: "0 2rem",
    })}
`;

const AboutContainer = styled.div`
    display: flex;
    justify-content: center;
    ${mobile({ display: "block" })}
`;

const Desc = styled.div`
    margin: 3rem;
    font-size: 1.6rem;
        font-family: FuturaPT-Book;
    font-weight: 300;
    margin-top: 1.5rem;
    line-height: 1.5;

    ${mobile({ display: "block", fontSize: "1.3rem" })}
`;

const LeftA = styled.div`
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    order: "1";
     ${mobile({ height: "80vh" })};
`;

const ImageA = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/longrich/about1_moIIfdRBN?updatedAt=1736785238142");
    background-size: cover;
    height: 80vh;
    background-position: center;
    ${mobile({
        margin: "0rem",
        height: "50vh",
        width: "100vw",
    })}
`;

const ImageB = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/longrich/about2_PTTzSNdxx?updatedAt=1736785301069");
    background-size: cover;
    height: 80vh;
    background-position: center;
    ${mobile({
        margin: "0rem",
        order: "2",
        height: "50vh",
        width: "100vw",
    })}
`;

const ImageC = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/longrich/about3_oP5Y3ycfZ?updatedAt=1736785381564");
    background-size: cover;
    height: 80vh;
    background-position: center;
    ${mobile({
        display: "none",
    })}
`;

const About = () => {
    return (
        <>
            <Container>
                <LeftA>
                    <Title>We're Your Partner In Adventure</Title>
                    <AboutContainer>
                        <Desc>
                        At Longrich Tour & Travel, we specialize in offering unforgettable travel experiences, tailored itineraries, and seamless tour services. Dedicated to exploring new destinations and creating enriching journeys, we’re committed to staying at the forefront of the travel industry. We continually strive to offer the latest trends and unique adventures that turn your travel dreams into reality.
                        </Desc>
                    </AboutContainer>
                </LeftA>
                <ImageA></ImageA>
            </Container>
            <Container>
                <ImageB></ImageB>
                <LeftA>
                    <Title>Vision and Mission</Title>
                    <AboutContainer>
                        <Desc>
                          
Our Vision is for Quantract Travel to become the leading partner for travelers and industry players seeking unforgettable, innovative, and expertly curated travel experiences.
                            <br></br>
                            <br></br>
                            Our Mission is to give our clients a unique edge by offering personalized itineraries, expert guidance, and seamless travel services that enhance and accelerate their journey to explore the world.
                        </Desc>
                    </AboutContainer>
                </LeftA>
            </Container>
            <Container>
                <LeftA>
                    <Title>Our Contributions To The World</Title>
                    <AboutContainer>
                        <Desc>
                        In today's travel industry, success depends on agility, personalization, and delivering exceptional experiences. We aim to enrich the world by offering innovative travel solutions and making meaningful contributions to the travel and tourism sector.
                        </Desc>
                    </AboutContainer>
                </LeftA>
                <ImageC></ImageC>
            </Container>
        </>
    );
};

export default About;
