import React from "react";
import About from "../Components/About";
import BannerOverlay from "../Components/BannerOverlay";

const AboutUs = () => {
    return (
        <div>
            <BannerOverlay imageUrl="https://ik.imagekit.io/dnddecpho/longrich/aboutbg_ocIQSxPSp?updatedAt=1736785393746" title="About Us"></BannerOverlay>
            <About />
        </div>
    );
};

export default AboutUs;
