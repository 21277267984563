import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from "react-router-dom";
import { UserProvider, UserConsumer } from "./Contexts/user";
import { ApolloClient } from "apollo-client";
import { ApolloProvider } from "@apollo/react-hooks";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import Routes from "./Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tours from "./Pages/Tours";
import Topbar from "./Components/topbar";
import { Footer } from "./Components/footer";
import Newsletter from "./Components/Newsletter";

let BASE_URL = undefined;
if (process.env.NODE_ENV === "production") {
    BASE_URL = "https://longrich.klerptech.com/";
    var httpLink = createHttpLink({
        uri: BASE_URL,
    });
} else if (process.env.NODE_ENV === "development") {
    var developmentLink = createHttpLink({
        uri: "http://localhost:5000",
    });
}

const authLink = setContext((_, { headers }) => {
    const authObj =
        JSON.parse(localStorage.getItem("auth")) ||
        JSON.parse(sessionStorage.getItem("auth"));

    return {
        headers: {
            ...headers,
            authorization: authObj ? `Bearer ${authObj.token}` : "",
        },
    };
});

const client = new ApolloClient({
    link: httpLink
        ? authLink.concat(httpLink)
        : authLink.concat(developmentLink),
    cache: new InMemoryCache(),
});

const App = () => {
    return (
        <ApolloProvider client={client}>
            <UserProvider>
                <UserConsumer>
                    {() => {
                        const PrivateRoute = ({
                            component: Component,
                            ...rest
                        }) => {
                            return (
                                <Route
                                    {...rest}
                                    render={(props) =>
                              <div className="relative">
                                        <Topbar />
                                        <Component {...props} />
                                        <Newsletter></Newsletter>
                                        <Footer />
                                        </div>
                                        
                                    }
                                />
                            );
                        };

                        return (
                            <Router>
                                    <Switch>

                                            {Routes.map((route, i) => (
                                                <PrivateRoute
                                                    exact
                                                    path={route.path}
                                                    component={route.component}
                                                    key={route.name}
                                                />
                                            ))}
                                            <PrivateRoute path="/packages/:tour">
                                           <div className="relative">
                                           <Topbar />
                                                <Tours />
                                                <Footer></Footer>
                                           </div>
                                            </PrivateRoute>
                                        <Redirect to="/" />
                                    </Switch>
                            </Router>
                        );
                    }}
                </UserConsumer>
                <ToastContainer />
            </UserProvider>
        </ApolloProvider>
    );
};

export default App;
