import styled from "styled-components";
import { mobile } from "../responsive";

const Header = styled.div`
    height: 30vh;
    width: 100vw;
    background: #25284b;
    position: relative;
    overflow: hidden;
    ${mobile({ height: "40vh" })}
`;

const Title = styled.h1`
    text-transform: uppercase;
    padding: 1rem;
    font-family: FuturaPT-Bold, sans-serif;
    font-size: 1.5rem;
    margin: 0;
    color: white;
    text-align: center;
    letter-spacing: 0.1rem;
     width: 70vw;
    ${mobile({ fontSize: "1.2rem", padding: "0.5rem" })}
`;

const Section = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    justify-content: center;
    display: grid;
    color: #fff;
    font-family: "Roboto", sans-serif;
    padding: 0 1rem;
    ${mobile({ top: "50%" })}
`;

const TitleOverlay = (imageUrl) => {
    return (
        <Header>
            <Section>
                <Title>{imageUrl.title}</Title>
                <Title>{imageUrl.cnTitle}</Title>
            </Section>
        </Header>
    );
};

export default TitleOverlay;
