import { ArrowLeftOutlined, ArrowRightOutlined } from "@material-ui/icons";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { sliderItems } from "../data";
import { mobile, tablet } from "../responsive";

const Container = styled.div`
    width: 100%;
    height: 75vh;
    display: flex;
    position: relative;
    overflow: hidden;
    ${mobile({ height: "40vh" })}
    ${tablet({ height: "100%" })}
`;

const Arrow = styled.div`
    width: 50px;
    height: 50px;
    background-color: #fff7f7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: ${(props) => props.direction === "left" && "10px"};
    right: ${(props) => props.direction === "right" && "10px"};
    margin: auto;
    cursor: pointer;
    opacity: 0.5;
    z-index: 2;
`;

const Wrapper = styled.div`
    height: 100vh; /* Match the full viewport height */
    display: flex;
    transition: all 1s ease;
    transform: translateX(${(props) => props.slideIndex * -100}vw);
`;


const Slide = styled.div`
    width: 100vw;
    height: 100vh; /* Make the slide take the full viewport height */
    display: flex;
    align-items: center;
    background: url("${(props) => props.bg}");
    background-position: center;
    background-size: cover; /* Ensure the image covers the full screen */
    background-repeat: no-repeat;
    cursor: pointer;

    ${(props) => tablet({
        background: `url(${props.mobilebg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh", /* Maintain full height on tablets */
        width: "100vw",
        backgroundRepeat: "no-repeat",
    })}

    ${(props) => mobile({
        background: `url(${props.mobilebg})`,
        backgroundSize: "cover", /* Full-screen coverage on mobile */
        backgroundPosition: "center",
        height: "100vh", /* Full height on mobile */
        backgroundRepeat: "no-repeat",
    })}
`;



const Slider = () => {
    const [slideIndex, setSlideIndex] = useState(0);
    const handleClick = (direction) => {
        if (direction === "left") {
            setSlideIndex(slideIndex > 0 ? slideIndex - 1 : sliderItems.length - 1);
        } else {
            // If current index is the last item, set index to a transitional value before transitioning to the first item
            if (slideIndex === sliderItems.length - 1) {
                setSlideIndex(-1); // Transitional value
                setTimeout(() => {
                    setSlideIndex(0); // Move to the first item after a short delay
                }, 50); // Adjust delay as needed for smoothness
            } else {
                setSlideIndex(slideIndex + 1);
            }
        }
    };
    

    useEffect(() => {
        const autoRotate = (direction) => {
            if (direction === "left") {
                setSlideIndex(slideIndex > 0 ? slideIndex - 1 : 2);
            } else {
                setSlideIndex(slideIndex < 2 ? slideIndex + 1 : 0);
            }
        };

        const intervalId = setInterval(() => {
            autoRotate("right");
        }, 5000);

        return () => clearInterval(intervalId);
    }, [slideIndex]);

    return (
        <Container>
            <Arrow direction="left" onClick={() => handleClick("left")}>
                <ArrowLeftOutlined />
            </Arrow>
            <Wrapper slideIndex={slideIndex}>
                {sliderItems.map((item) => (
                    <Slide mobilebg={item.mobileImg} bg={item.img} key={item.id} onClick={() => window.location.href = item.url}></Slide>
                ))}
            </Wrapper>
            <Arrow direction="right" onClick={() => handleClick("right")}>
                <ArrowRightOutlined />
            </Arrow>
        </Container>
    );
};

export default Slider;
