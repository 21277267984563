import { Link } from "react-router-dom";
import styled from "styled-components";
import {
    List,
    Close,
} from "@material-ui/icons";
import { useState } from "react";
import { mobile } from "../responsive";

const Container = styled.div``;

const MenuContainer = styled.div`
    display: none;
    ${mobile({
        display: "flex",
        alignItems: "center",
        padding: "10px 15px",
        background: "white",
        color: "black",
    })}
`;

const Dropddown = styled.div`
    padding-top: 15px;
    height: 100vh;
    color: black;
    display: flex;
    flex-direction: column;
`;

const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const Logo = styled.img`
    height: 50px;
`;

const Left = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
`;

const Center = styled.div`
    font-size: 1.5rem;
    cursor: pointer;
    font-family: FuturaPT-Bold;
    flex: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Right = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
`;

const MobileNavbar = () => {
    const [showMenu, setShowMenu] = useState(false);
    return (
        <>
            <Container>
                {" "}
                <MenuContainer>
                    {" "}
                    <Left>
                        {" "}
                        {showMenu ? (
                            <Close
                                onClick={() => {
                                    setShowMenu(false);
                                }}
                            ></Close>
                        ) : (
                            <>
                                <List
                                    onClick={() => {
                                        setShowMenu(true);
                                    }}
                                ></List>
                            </>
                        )}
                    </Left>
                    <Center>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",
                            }}
                            to="/"
                        >
                            <LogoContainer>
                            <Logo
                                src={
                                    "https://ik.imagekit.io/dnddecpho/longrich/longrichlogo_As_FxQXBm.png?updatedAt=1714729978154"
                                }
                            ></Logo>
                            </LogoContainer>
                        </Link>
                    </Center>
                    <Right>
                    </Right>
                </MenuContainer>
                {showMenu && (
                    <Container>
                        <Dropddown>
                            {" "}
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                to="/"
                                onClick={() => {
                                    setShowMenu(false);
                                }}
                            >
                                Home
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                 to="/?area=Asia"
                                onClick={() => {
                                    setShowMenu(false);
                                }}
                            >
                                Asia
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                to="/?area=China"
                                onClick={() => {
                                    setShowMenu(false);
                                }}
                            >
                                China
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                 to="/?area=Europe"
                                onClick={() => {
                                    setShowMenu(false);
                                }}
                            >
                                Europe
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                             to="/?area=Cruises"
                                onClick={() => {
                                    setShowMenu(false);
                                }}
                            >
                                Cruises
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                to="/about"
                                onClick={() => {
                                    setShowMenu(false);
                                }}
                            >
                                About
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                to="/contact"
                                onClick={() => {
                                    setShowMenu(false);
                                }}
                            >
                                Contact
                            </Link>
                        </Dropddown>
                    </Container>
                )}
            </Container>
        </>
    );
};

export default MobileNavbar;
